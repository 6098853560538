import './CompassControl.css';

class CompassControl {
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group compass-control-button'; // Updated container class name

    // Compass button
    const compassButton = this._createCompassButton('mapboxgl-ctrl-icon mapboxgl-ctrl-compass');
    compassButton.onclick = () => {
      this.map.easeTo({ bearing: 0 }); // Smoothly reset the map's bearing to North
    };
    this.container.appendChild(compassButton);

    // Update compass rotation based on bearing
    this.map.on('rotate', () => {
      const bearing = this.map.getBearing();
      compassButton.style.transform = `rotate(${-bearing}deg)`;
    });

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }

  _createCompassButton(className) {
    const button = document.createElement('button');
    button.className = className;
    button.type = 'button';
    button.style.marginTop = '1rem'; // Add margin to position it below the zoom buttons
    return button;
  }
}

export default CompassControl;
