import { configureStore } from '@reduxjs/toolkit';
import userSessionReducer from './reducers/userSessionReducer';
import mapReducer from './reducers/mapReducer';
import userReducer from './reducers/userReducer';
import authReducer from './reducers/authReducer';
import { loadState, saveState } from './localStorage';

const preloadedState = loadState();

const store = configureStore({
  reducer: {
    userSession: userSessionReducer,
    map: mapReducer,
    user: userReducer,
    auth: authReducer,
  },
  preloadedState,
});

store.subscribe(() => {
  saveState({
    userSession: store.getState().userSession,
    user: store.getState().user,
    auth: store.getState().auth,
  });
});

export default store;
