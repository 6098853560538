import React from 'react';
import './CitySelectorModal.css';

const CitySelectorModal = ({ isVisible, onClose, cities, onCitySelect }) => {
  if (!isVisible) return null;

  return (
    <div className="city-selector-modal-overlay">
      <div className="city-selector-modal-content">
        <h2 className="city-selector-modal-title">Select a City</h2>
        <div className="city-buttons-container">
          {cities.map((city) => (
            <button
              key={city.value}
              className="city-button"
              onClick={() => onCitySelect(city)}
            >
              {city.label}
            </button>
          ))}
        </div>
        <button onClick={onClose} className="city-selector-modal-close-button">Close</button>
      </div>
    </div>
  );
};

export default CitySelectorModal;
