import React, { useState } from 'react';
import Header from '../Base/Header';
import Search from '../Filters/Search';
import Map from '../Map/Map';
import InfoPanel from '../InfoPanel/InfoPanel';
import Modals from '../Modals/Modals';
import './Layout.css';

const Layout = ({ handleOpenModal, handleCloseModal, isModalVisible, modalContent }) => {
  const [isPanelOpen, setPanelOpen] = useState(true);
  const [mapKey, setMapKey] = useState(0); // Key to force layout refresh

  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
    setMapKey(prevKey => prevKey + 1); // Update key to force layout refresh
  };

  return (
    <div className="layout">
      <header className="layout-header">
        <Header />
      </header>
      <div className="layout-filters">
        <Search />
      </div>
      <div className="layout-content">
        <div className="desktop-layout">
          <InfoPanel isPanelOpen={isPanelOpen} togglePanel={togglePanel} mapKey={mapKey} />
          <div key={mapKey} className={`map-container ${isPanelOpen ? '' : 'expanded'}`}>
            <Map />
          </div>
        </div>
        <div className="mobile-layout">
          <div className="map-container">
            <Map />
          </div>
        </div>
      </div>
      <Modals isVisible={isModalVisible} onClose={handleCloseModal} title={modalContent.title} content={modalContent.content} />
    </div>
  );
};

export default Layout;
