import React from 'react';
import UserOptions from '../Settings/UserOptions';
import Auth from '../Auth/Auth';
import './Header.css';

const Header = () => {
  return (
    <div className="header">
      <h1 className="title">walky.ai</h1>
      <div className="header-right">
        <span className="user-options">
          <UserOptions />
        </span>
        <Auth />
      </div>
    </div>
  );
};

export default Header;
