import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './Map.css';
import MapControls from './MapControls';
import ZoomControl from './ZoomControl';
import CompassControl from './CompassControl';
import { handleGeoJsonOperation, handleGeoJsonState } from './mapUtils';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Map = ({ geoj, layerid = '0', oper = 'null' }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const center = useSelector((state) => state.map.center);
  const is3D = useSelector((state) => state.map.is3D);
  const [geoJson, setGeoJson] = useState(null);

  const addGeoJson = (geoj, layerid, oper) => {
    setGeoJson({ geoj, layerid, oper });
  };

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [center.longitude, center.latitude],
        zoom: 12,
      });

      // Add custom zoom control buttons to the top-right corner
      const zoomControl = new ZoomControl();
      map.current.addControl(zoomControl, 'top-right');

      // Add custom compass control to the top-right corner
      const compassControl = new CompassControl();
      map.current.addControl(compassControl, 'top-right');

      // Add scale control to the bottom-right corner
      const scale = new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: 'imperial'
      });
      map.current.addControl(scale, 'bottom-right');
    } else {
      map.current.setCenter([center.longitude, center.latitude]);
    }
  }, [center]);

  useEffect(() => {
    if (map.current) {
      if (is3D) {
        map.current.setPitch(60); // 3D view
      } else {
        map.current.setPitch(0); // 2D view
      }
    }
  }, [is3D]);

  useEffect(() => {
    handleGeoJsonOperation(map.current, geoj, layerid, oper);
  }, [geoj, layerid, oper]);

  useEffect(() => {
    handleGeoJsonState(map.current, geoJson);
  }, [geoJson]);

  return (
    <div className="map-wrapper">
      <div ref={mapContainer} className="map-container" />
      <MapControls addGeoJson={addGeoJson} mapInstance={map.current} />
    </div>
  );
};

export default Map;
