import { createReducer } from '@reduxjs/toolkit';
import {
  setUserProfile,
  updateUserProfile,
  setSubscriptionLevel,
  loginUser,
  logoutUser,
  updateLastActivity,
} from '../actions/userActions';

const initialState = {
  profile: {
    preferences: {},
    favorites: [],
  },
  subscriptionLevel: 'free',
  isAuthenticated: false,
  lastActivity: null,
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserProfile, (state, action) => {
      state.profile = action.payload;
      localStorage.setItem('userProfile', JSON.stringify(action.payload));
    })
    .addCase(updateUserProfile, (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
      localStorage.setItem('userProfile', JSON.stringify(state.profile));
    })
    .addCase(setSubscriptionLevel, (state, action) => {
      state.subscriptionLevel = action.payload;
      localStorage.setItem('subscriptionLevel', action.payload);
    })
    .addCase(loginUser, (state) => {
      state.isAuthenticated = true;
      localStorage.setItem('isAuthenticated', 'true');
    })
    .addCase(logoutUser, (state) => {
      state.isAuthenticated = false;
      state.profile = { preferences: {}, favorites: [] };
      state.subscriptionLevel = 'free';
      localStorage.removeItem('userProfile');
      localStorage.removeItem('subscriptionLevel');
      localStorage.removeItem('isAuthenticated');
    })
    .addCase(updateLastActivity, (state, action) => {
      state.lastActivity = action.payload;
      localStorage.setItem('lastActivity', action.payload);
    });
});

export default userReducer;
