import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggle3D } from '../../store/actions/mapActions';
import createLineGeoJson from '../../utils/createLineGeoJson';
import getMapCenter from '../../utils/getMapCenter';
import './MapControls.css';

const MapControls = ({ addGeoJson, mapInstance }) => {
  const dispatch = useDispatch();
  const is3D = useSelector((state) => state.map.is3D);

  const [isSolarLunarActive, setIsSolarLunarActive] = useState(false);

  const handleToggle3D = () => {
    dispatch(toggle3D());
  };

  const handleToggleSolarLunar = async () => {
    setIsSolarLunarActive(!isSolarLunarActive);

    if (!isSolarLunarActive) {
      // Ensure map instance is available
      while (!mapInstance || typeof mapInstance.getCenter !== 'function') {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const center = getMapCenter(mapInstance);

      const geoJson = createLineGeoJson(
        [[center.longitude, center.latitude], [center.longitude + 0.01, center.latitude + 0.01]],
        '#dc2626',
        '4px'
      );

      addGeoJson(geoJson, 'solarLunarLayer', 'add');
    } else {
      addGeoJson(null, 'solarLunarLayer', 'delete');
    }
  };

  return (
    <div className="map-controls">
      <button className="toggle-button" onClick={handleToggle3D}>
        {is3D ? '2D' : '3D'}
      </button>
      <button
        className={`control-button ${isSolarLunarActive ? 'active' : ''}`}
        onClick={handleToggleSolarLunar}
      >
        S
      </button>
    </div>
  );
};

export default MapControls;
