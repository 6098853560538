const CITY_DATA_VERSION = '1.0';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);

    // Check city data version
    const storedVersion = localStorage.getItem('cityDataVersion');
    if (storedVersion !== CITY_DATA_VERSION) {
      state.userSession.cityData = [];
      localStorage.setItem('cityDataVersion', CITY_DATA_VERSION);
    }

    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors
  }
};
