import { createReducer } from '@reduxjs/toolkit';
import { setUser, setAuthError } from '../actions/authActions';

const initialState = {
  user: null,
  error: null,
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUser, (state, action) => {
      state.user = action.payload;
      if (action.payload) {
        localStorage.setItem('user', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('user');
      }
    })
    .addCase(setAuthError, (state, action) => {
      state.error = action.payload;
    });
});

export default authReducer;
