// Utility to center map based on user's location or IP address

// Function to get location based on IP address
export const getLocationByIP = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      return {
        latitude: data.latitude,
        longitude: data.longitude,
      };
    } catch (error) {
      console.error('Error fetching location by IP:', error);
      return null;
    }
  };
  
  // Function to get location using browser's geolocation API
  export const getLocationByBrowser = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            console.error('Error fetching location by browser:', error);
            reject(error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };
  
  // Function to get the best available location
  export const getBestLocation = async () => {
    try {
      const browserLocation = await getLocationByBrowser();
      return browserLocation;
    } catch (error) {
      console.error('Error using browser location, falling back to IP location.');
      const ipLocation = await getLocationByIP();
      return ipLocation;
    }
  };
  