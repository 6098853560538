import { createReducer } from '@reduxjs/toolkit';
import {
  updateMapProperties,
  addGeojsonObject,
  removeGeojsonObject,
  updateGeojsonObject,
  selectObject,
  addPopup,
  removePopup,
  addInfoCard,
  removeInfoCard,
  setCityData,
  updateCityData,
  setLastVisitedCities,
} from '../actions/userSessionActions';

const initialState = {
  mapProperties: {
    center: [-74.9712, 40.7742],
    zoom: 10,
    bounds: null,
    pitch: 0,
    bearing: 0,
    localtime: null,
  },
  geojsonObjects: [],
  selectedObject: null,
  popups: [],
  infoCards: [],
  cityData: [],
  lastVisitedCities: [],
};

const userSessionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateMapProperties, (state, action) => {
      state.mapProperties = { ...state.mapProperties, ...action.payload };
    })
    .addCase(addGeojsonObject, (state, action) => {
      state.geojsonObjects.push(action.payload);
    })
    .addCase(removeGeojsonObject, (state, action) => {
      state.geojsonObjects = state.geojsonObjects.filter(obj => obj.id !== action.payload);
    })
    .addCase(updateGeojsonObject, (state, action) => {
      state.geojsonObjects = state.geojsonObjects.map(obj =>
        obj.id === action.payload.id ? action.payload : obj
      );
    })
    .addCase(selectObject, (state, action) => {
      state.selectedObject = action.payload;
    })
    .addCase(addPopup, (state, action) => {
      state.popups.push(action.payload);
    })
    .addCase(removePopup, (state, action) => {
      state.popups = state.popups.filter(popup => popup.id !== action.payload);
    })
    .addCase(addInfoCard, (state, action) => {
      state.infoCards.push(action.payload);
    })
    .addCase(removeInfoCard, (state, action) => {
      state.infoCards = state.infoCards.filter(card => card.id !== action.payload);
    })
    .addCase(setCityData, (state, action) => {
      state.cityData = action.payload;
      localStorage.setItem('cityData', JSON.stringify(action.payload));
    })
    .addCase(updateCityData, (state, action) => {
      state.cityData = state.cityData.map(city =>
        city.id === action.payload.id ? action.payload : city
      );
      localStorage.setItem('cityData', JSON.stringify(state.cityData));
    })
    .addCase(setLastVisitedCities, (state, action) => {
      state.lastVisitedCities = action.payload;
      localStorage.setItem('lastVisitedCities', JSON.stringify(action.payload));
    });
});

export default userSessionReducer;
