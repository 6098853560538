export const handleGeoJsonOperation = (map, geoj, layerid, oper) => {
    if (!map || oper === 'null') return;
  
    if (oper === 'delete') {
      if (map.getLayer(layerid)) {
        map.removeLayer(layerid);
      }
      if (map.getSource(layerid)) {
        map.removeSource(layerid);
      }
    } else if (oper === 'add' && geoj) {
      if (map.getSource(layerid)) {
        map.removeLayer(layerid);
        map.removeSource(layerid);
      }
  
      map.addSource(layerid, {
        type: 'geojson',
        data: geoj,
      });
  
      map.addLayer({
        id: layerid,
        type: 'line',
        source: layerid,
        paint: {
          'line-color': geoj.properties.line_color,
          'line-width': parseInt(geoj.properties.line_weight),
        },
      });
    }
  };
  
  export const handleGeoJsonState = (map, geoJson) => {
    if (!map || !geoJson) return;
  
    if (geoJson.oper === 'delete') {
      if (map.getLayer(geoJson.layerid)) {
        map.removeLayer(geoJson.layerid);
      }
      if (map.getSource(geoJson.layerid)) {
        map.removeSource(geoJson.layerid);
      }
    } else if (geoJson.oper === 'add') {
      if (map.getSource(geoJson.layerid)) {
        map.removeLayer(geoJson.layerid);
        map.removeSource(geoJson.layerid);
      }
  
      map.addSource(geoJson.layerid, {
        type: 'geojson',
        data: geoJson.geoj,
      });
  
      map.addLayer({
        id: geoJson.layerid,
        type: 'line',
        source: geoJson.layerid,
        paint: {
          'line-color': geoJson.geoj.properties.line_color,
          'line-width': parseInt(geoJson.geoj.properties.line_weight),
        },
      });
    }
  };
  