/**
 * Creates a GeoJSON LineString object with specified properties.
 *
 * @param {Array} coordinates - Array of latitude and longitude coordinates.
 * @param {string} lineColor - Color of the line in hex format, e.g., #dc2626.
 * @param {string} thickness - Thickness of the line, e.g., '4px'.
 * @returns {Object} - A GeoJSON LineString object.
 */
const createLineGeoJson = (coordinates, lineColor, thickness) => {
    if (!Array.isArray(coordinates) || coordinates.length === 0) {
      throw new Error('Coordinates should be a non-empty array');
    }
  
    if (typeof lineColor !== 'string') {
      throw new Error('Line color should be a string');
    }
  
    if (typeof thickness !== 'string') {
      throw new Error('Thickness should be a string');
    }
  
    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coordinates,
      },
      properties: {
        line_color: lineColor,
        line_weight: thickness,
      },
    };
  };
  
  export default createLineGeoJson;
  