/**
 * Gets the center of the map.
 * 
 * @param {Object} map - The Mapbox GL JS map instance.
 * @returns {Object} - An object containing the latitude and longitude of the center.
 */
const getMapCenter = (map) => {
    if (!map || typeof map.getCenter !== 'function') {
      throw new Error('A valid map instance is required');
    }
    
    const center = map.getCenter();
    return {
      latitude: center.lat,
      longitude: center.lng,
    };
  };
  
  export default getMapCenter;
  