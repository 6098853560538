import React from 'react';
import './UserFavorites.css';

const UserFavorites = ({ isVisible, onClose, favorites }) => {
  if (!isVisible) return null;

  return (
    <div className="user-favorites-modal-overlay">
      <div className="user-favorites-modal-content">
        <h2 className="user-favorites-modal-title">My Places</h2>
        <div className="favorites-list">
          {favorites.map((place) => (
            <div key={place.id} className="favorite-item">
              {place.name}
            </div>
          ))}
        </div>
        <button onClick={onClose} className="user-favorites-modal-close-button">Close</button>
      </div>
    </div>
  );
};

export default UserFavorites;
