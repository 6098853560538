import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faMap, faList, faCity, faSearch, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import CitySelectorModal from '../Modals/CitySelectorModal';
import { setMapCenter } from '../../store/actions/mapActions';
import FilterButtons from './FilterButtons';
import './Search.css';
import './ReactSelect.css';

const Search = () => {
  const [isMapView, setIsMapView] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isCitySelectorVisible, setCitySelectorVisible] = useState(false);
  const dispatch = useDispatch();

  const toggleView = () => {
    setIsMapView(!isMapView);
  };

  const handleSearchChange = (selectedOption) => {
    setSearchValue(selectedOption);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const openCitySelector = () => {
    setCitySelectorVisible(true);
  };

  const closeCitySelector = () => {
    setCitySelectorVisible(false);
  };

  const handleCitySelect = (city) => {
    setSearchValue(city);
    dispatch(setMapCenter({ latitude: city.latitude, longitude: city.longitude }));
    closeCitySelector();
  };

  const searchOptions = [
    { value: 'new_york', label: 'New York', latitude: 40.712776, longitude: -74.005974 },
    { value: 'los_angeles', label: 'Los Angeles', latitude: 34.052235, longitude: -118.243683 },
    { value: 'chicago', label: 'Chicago', latitude: 41.878113, longitude: -87.629799 },
    // Add more options as needed
  ];

  return (
    <div className="search-container">
      <div className="group-a">
        <button onClick={toggleView} className="toggle-view-button">
          <FontAwesomeIcon icon={isMapView ? faList : faMap} />
        </button>
        <div className="search-input-wrapper">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <Select
            classNamePrefix="custom-react-select"
            options={searchOptions}
            placeholder="Central park"
            value={searchValue}
            onChange={handleSearchChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            isClearable
            menuIsOpen={inputValue.length > 0} // Only show the menu when there's input
          />
        </div>
        <button onClick={openCitySelector} className="switch-city-button">
          <FontAwesomeIcon icon={faCity} className="icon" />
          City
        </button>
      </div>
      <div className="group-b">
        <button className="map-settings-icon">
          <FontAwesomeIcon icon={faMapMarkedAlt} />
        </button>
        <button className="filter-button">
          <FontAwesomeIcon icon={faFilter} />
        </button>
        <div className="group-c-wrapper">
          <FilterButtons />
        </div>
      </div>
      <CitySelectorModal
        isVisible={isCitySelectorVisible}
        onClose={closeCitySelector}
        cities={searchOptions}
        onCitySelect={handleCitySelect}
      />
    </div>
  );
};

export default Search;
