import React from 'react';
import './UserOptions.css';

const UserOptions = () => {
  return (
    <div className="user-options">
      <h2 className="options-title">Preferences</h2>
    </div>
  );
};

export default UserOptions;
