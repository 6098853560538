import React from 'react';
import './UserSubscriptions.css';

const UserSubscriptions = ({ isVisible, onClose, subscriptions }) => {
  if (!isVisible) return null;

  return (
    <div className="user-subscriptions-modal-overlay">
      <div className="user-subscriptions-modal-content">
        <h2 className="user-subscriptions-modal-title">My Subscriptions</h2>
        <div className="subscriptions-list">
          {subscriptions.map((subscription) => (
            <div key={subscription.id} className="subscription-item">
              {subscription.name}
            </div>
          ))}
        </div>
        <button onClick={onClose} className="user-subscriptions-modal-close-button">Close</button>
      </div>
    </div>
  );
};

export default UserSubscriptions;
