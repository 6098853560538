import { createAction } from '@reduxjs/toolkit';
import { supabase } from '../../supabaseClient';

export const setUser = createAction('auth/setUser');
export const setAuthError = createAction('auth/setAuthError');

export const signInWithGoogle = () => async (dispatch) => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
  });

  if (error) {
    dispatch(setAuthError(error.message));
  } else {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        dispatch(setUser(session.user));
      }
    });
  }
};

export const signOut = () => async (dispatch) => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    dispatch(setAuthError(error.message));
  } else {
    dispatch(setUser(null));
  }
};
