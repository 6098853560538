import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, logoutUser, setUserProfile } from '../../store/actions/userActions';
import UserAutoIcon from './UserAutoIcon';
import './Auth.css';

const Auth = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const user = session.user;
        dispatch(loginUser());
        dispatch(setUserProfile(user));
        setUsername(user.email.split('@')[0].substring(0, 2));
      }

      supabase.auth.onAuthStateChange((_event, session) => {
        if (session) {
          const user = session.user;
          dispatch(loginUser());
          dispatch(setUserProfile(user));
          setUsername(user.email.split('@')[0].substring(0, 2));
        } else {
          dispatch(logoutUser());
        }
      });
    };

    checkSession();
  }, [dispatch]);

  const handleLogin = async () => {
    setLoading(true);
    console.log('=>Redirect URL:', process.env.REACT_APP_REDIRECT_URL);
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: process.env.REACT_APP_REDIRECT_URL,
      },
    });
    if (error) console.log('Error logging in:', error.message);
    setLoading(false);
  };

  return (
    <div className="auth-container">
      {user.isAuthenticated ? (
        <UserAutoIcon username={username} />
      ) : (
        <button onClick={handleLogin} disabled={loading}>
          {loading ? 'Loading...' : 'Login with Google'}
        </button>
      )}
    </div>
  );
};

export default Auth;
