import { createAction } from '@reduxjs/toolkit';

export const updateMapProperties = createAction('userSession/updateMapProperties');
export const addGeojsonObject = createAction('userSession/addGeojsonObject');
export const removeGeojsonObject = createAction('userSession/removeGeojsonObject');
export const updateGeojsonObject = createAction('userSession/updateGeojsonObject');
export const selectObject = createAction('userSession/selectObject');
export const addPopup = createAction('userSession/addPopup');
export const removePopup = createAction('userSession/removePopup');
export const addInfoCard = createAction('userSession/addInfoCard');
export const removeInfoCard = createAction('userSession/removeInfoCard');
export const setCityData = createAction('userSession/setCityData');
export const updateCityData = createAction('userSession/updateCityData');
export const setLastVisitedCities = createAction('userSession/setLastVisitedCities');
