import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { supabase } from '../../supabaseClient';
import { logoutUser } from '../../store/actions/userActions';
import UserFavorites from '../Modals/UserFavorites';
import UserSubscriptions from '../Modals/UserSubscriptions';
import './UserAutoIcon.css';

const UserAutoIcon = ({ username }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFavoritesVisible, setFavoritesVisible] = useState(false);
  const [isSubscriptionsVisible, setSubscriptionsVisible] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log('Error logging out:', error.message);
    dispatch(logoutUser());
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleOpenFavorites = () => {
    setFavoritesVisible(true);
    setIsOpen(false);
  };

  const handleOpenSubscriptions = () => {
    setSubscriptionsVisible(true);
    setIsOpen(false);
  };

  return (
    <div className="user-auto-icon-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button className="user-logo">
        {username}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={handleOpenFavorites}>My Places</div>
          <div className="dropdown-item" onClick={handleOpenSubscriptions}>My Subscriptions</div>
          <div className="dropdown-item" onClick={handleLogout}>Logout</div>
        </div>
      )}
      <UserFavorites isVisible={isFavoritesVisible} onClose={() => setFavoritesVisible(false)} favorites={[]} />
      <UserSubscriptions isVisible={isSubscriptionsVisible} onClose={() => setSubscriptionsVisible(false)} subscriptions={[]} />
    </div>
  );
};

export default UserAutoIcon;
