import React, { useRef, useEffect, useState } from 'react';
import './FilterButtons.css';

const FilterButtons = () => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState('100%');

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(`${containerRef.current.parentElement.offsetWidth}px`);
      }
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const handleMouseDown = (e) => {
    containerRef.current.isDown = true;
    containerRef.current.startX = e.pageX - containerRef.current.offsetLeft;
    containerRef.current.scrollLeftStart = containerRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    containerRef.current.isDown = false;
  };

  const handleMouseUp = () => {
    containerRef.current.isDown = false;
  };

  const handleMouseMove = (e) => {
    if (!containerRef.current.isDown) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - containerRef.current.startX) * 2; // Adjust the scrolling speed
    containerRef.current.scrollLeft = containerRef.current.scrollLeftStart - walk;
  };

  const handleTouchStart = (e) => {
    containerRef.current.isDown = true;
    containerRef.current.startX = e.touches[0].pageX - containerRef.current.offsetLeft;
    containerRef.current.scrollLeftStart = containerRef.current.scrollLeft;
  };

  const handleTouchEnd = () => {
    containerRef.current.isDown = false;
  };

  const handleTouchMove = (e) => {
    if (!containerRef.current.isDown) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - containerRef.current.startX) * 2; // Adjust the scrolling speed
    containerRef.current.scrollLeft = containerRef.current.scrollLeftStart - walk;
  };

  return (
    <div
      className="filter-buttons-container"
      style={{ width: containerWidth, maxWidth: '100%' }}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      <button className="filter-button">Filter 1</button>
      <button className="filter-button">Filter 2</button>
      <button className="filter-button">Filter 3</button>
      <button className="filter-button">Filter 4</button>
      <button className="filter-button">Filter 5</button>
      <button className="filter-button">Filter 6</button>
      <button className="filter-button">Filter 7</button>
      {/* Add more filter buttons as needed */}
    </div>
  );
};

export default FilterButtons;
