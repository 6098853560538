import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from './components/Layout/Layout';
import { getBestLocation } from './utils/mapCenterLocator';
import { setMapCenter } from './store/actions/mapActions';
import './App.css';

const App = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });
  const dispatch = useDispatch();

  const handleOpenModal = (title, content) => {
    setModalContent({ title, content });
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const centerMap = async () => {
      const location = await getBestLocation();
      if (location) {
        dispatch(setMapCenter(location));
      }
    };
    centerMap();
  }, [dispatch]);

  console.log('Environment:', process.env.NODE_ENV);
  console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL);
  console.log('Redirect URL:', process.env.REACT_APP_REDIRECT_URL);

  return (
    <div className="App">
      <Layout
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        isModalVisible={isModalVisible}
        modalContent={modalContent}
      />
    </div>
  );
};

export default App;
