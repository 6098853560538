import { createReducer } from '@reduxjs/toolkit';
import {
  setMapCenter,
  toggle3D,
  setMapZoom,
  setMapBounds,
  setMapPitch,
  setMapBearing,
  setLocalTime,
} from '../actions/mapActions';

const initialState = {
  center: { latitude: 0, longitude: 0 },
  is3D: false,
  zoom: 10,
  bounds: null,
  pitch: 0,
  bearing: 0,
  localtime: null,
};

const mapReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setMapCenter, (state, action) => {
      state.center = action.payload;
    })
    .addCase(toggle3D, (state) => {
      state.is3D = !state.is3D;
    })
    .addCase(setMapZoom, (state, action) => {
      state.zoom = action.payload;
    })
    .addCase(setMapBounds, (state, action) => {
      state.bounds = action.payload;
    })
    .addCase(setMapPitch, (state, action) => {
      state.pitch = action.payload;
    })
    .addCase(setMapBearing, (state, action) => {
      state.bearing = action.payload;
    })
    .addCase(setLocalTime, (state, action) => {
      state.localtime = action.payload;
    });
});

export default mapReducer;
