import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './InfoPanel.css';

const InfoPanel = ({ isPanelOpen, togglePanel, mapKey }) => {
  return (
    <div key={mapKey} className={`info-panel ${isPanelOpen ? 'open' : 'closed'}`}>
      {isPanelOpen ? (
        <>
          <button className="panel-close-button" onClick={togglePanel}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <div className="info-content">
            Info Panel Content
          </div>
        </>
      ) : (
        <button className="panel-open-button" onClick={togglePanel}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      )}
    </div>
  );
};

export default InfoPanel;
