import './ZoomControl.css';

class ZoomControl {
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    // Zoom in button
    const zoomInButton = this._createButton('mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in', () => {
      this.map.zoomIn();
    });
    this.container.appendChild(zoomInButton);

    // Zoom out button
    const zoomOutButton = this._createButton('mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out', () => {
      this.map.zoomOut();
    });
    this.container.appendChild(zoomOutButton);

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }

  _createButton(className, fn) {
    const button = document.createElement('button');
    button.className = className;
    button.type = 'button';
    button.onclick = fn;
    return button;
  }
}

export default ZoomControl;
